<template>
	<footer class="text-white text-center py-3 footer">
		<div class="container">
			<p class="footer-title">Contact ENVY Image Studio</p>
			<div class="social-icons mb-3">
				<router-link to="/contact"><img src="@/assets/images/icon-email.png" alt="Email"></router-link>
				<a :href="homepageData.instagram" target="_blank"><img src="@/assets/images/icon-instagram.png" alt="Instagram"></a>
				<a :href="homepageData.facebook" target="_blank"><img src="@/assets/images/icon-facebook.png" alt="Facebook"></a>
			</div>
			<p class="footer-copyright">&copy; 2024 ENVY Image Studio</p>
		</div>
	</footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  data() {
    return {
      homepageData: ''
    };
  },
  created() {
    this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        const response = await fetch('/api/content/page_home');
        const data = await response.json();
        if (data && data.content) {
          this.homepageData = JSON.parse(data.content);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
        this.homepageData = '';
      }
    }
  }
}
</script>