import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import fetchWrapper from '@/utils/fetchWrapper';

if (process.env.NODE_ENV == "development") {
  window.fetch = fetchWrapper;
}

const app = createApp(App);

router.afterEach(() => {
  
  // Collapse the navbar
  const navbarCollapse = document.querySelector('.navbar-collapse');
  if (navbarCollapse && navbarCollapse.classList.contains('show')) {
    navbarCollapse.classList.remove('show');
  }
});

app.use(router);
app.mount('#app');
