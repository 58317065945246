<template>
  <div id="app">
    <div v-if="isAdminRoute" id="wrapper">
      <AdminHeader />
      <div  id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <AdminTopNav />
          <router-view />
        </div>
        <AdminFooter />
      </div>
    </div>
    <div v-else>
      <SiteHeader  />
      <router-view/>
      <SiteFooter/>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import SiteHeader from './components/SiteHeader.vue';
import SiteFooter from './components/SiteFooter.vue';
import AdminHeader from './components/admin/AdminHeader.vue';
import AdminFooter from './components/admin/AdminFooter.vue';
import AdminTopNav from './components/admin/AdminTopNav.vue';

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter,
    AdminHeader,
    AdminFooter,
    AdminTopNav
  },
  setup() {

    const route = useRoute();
    
    // Compute whether the current route starts with '/admin'
    const isAdminRoute = computed(() => {
      return route.path.startsWith('/admin');
    });

    return {
      isAdminRoute,
    };
  }
}
</script>

<style src="./assets/styles/main.scss" lang="scss"></style>
